
import { defineComponent, ref, computed, reactive, onUnmounted } from 'vue'
import Popup from '@/components/Popup.vue'
import router from '@/router'
import Button from '@/components/UI/Button.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import getCarBrandsModels from '@/mixins/getCarBrandsModels'
import Loader from '@/components/UI/Loader.vue'
import { useStore } from '@/store'
import { carBrandsModelType } from '@/types'
import { MutationTypes } from '@/store/options/mutations'
export default defineComponent({
  components: {
    Button,
    Dropdown,
    Loader,
    Popup
  },
  setup() {
    const store = useStore()
    const componentKey = ref(0)
    const getCarPolicyBrandsModels = computed(() => store.state.auth.carPolicyBrandsModels)
    const filteredBrands = computed(() =>
      store.getters.filteredBrands.map((item) => {
        return item.brand
      })
    )
    const searchString = computed(() => store.state.options.searchString)
    const searchModels = computed(() => store.state.options.searchModels)
    const filteredModels = computed(() => store.getters.filteredModels)
    const { carBrandsModels, displayUserSettingPopup, goToAccount } = getCarBrandsModels()
    const selectedBrand = ref<string>('')
    const selectedModel = ref('')
    const models = ref()
    const brandModelsArray: { value: carBrandsModelType[] } = reactive({
      value: [{ brand: '', models: [] }]
    })
    const loader = computed(() => store.state.auth.loader)
    const selectBrand = (brand) => {
      let availableCarBrandsModels = carBrandsModels
      selectedModel.value = ''
      selectedBrand.value = brand
      if (getCarPolicyBrandsModels.value.length) {
        availableCarBrandsModels = getCarPolicyBrandsModels
      }
      const getModels = availableCarBrandsModels.value.filter((item) => {
        return item['brand'] == brand
      })
      let getAvailableModels
      if (!getModels[0].models.length) {
        getAvailableModels = carBrandsModels.value.find((item) => item.brand === brand)
      } else {
        getAvailableModels = getModels[0]
      }
      models.value = getAvailableModels.models.sort()
      store.commit(MutationTypes.SET_SELECTED_MODELS, models.value)
      brandModelsArray['value'][0]['brand'] = selectedBrand.value
      store.commit(MutationTypes.SET_SEARCH_STRING, brand)
      store.commit(MutationTypes.SET_SEARCH_MODELS, '')
      componentKey.value += 1
    }
    const selectModel = (item) => {
      selectedModel.value = item
      brandModelsArray['value'][0]['models'][0] = item.toLowerCase()
      sessionStorage.setItem('brands', JSON.stringify(brandModelsArray.value))
      store.commit(MutationTypes.SET_SEARCH_MODELS, item)
      componentKey.value += 1
    }
    const goToWizard = () => {
      sessionStorage.removeItem('brands')
      sessionStorage.removeItem('selectedIndex')
      // remove carPolicy settings
      sessionStorage.removeItem('plug_in_hybrid')
      sessionStorage.removeItem('hybrid')
      sessionStorage.removeItem('plug_in_hybrid_drive_range')
      sessionStorage.removeItem('electric_drive_range')
      sessionStorage.removeItem('fuel_type')
      sessionStorage.removeItem('body_type')
      sessionStorage.removeItem('doors')
      router.push({ name: 'step1Wizard' })
    }
    const isBrandModelExist = computed(() => {
      let isBrandModel = false
      if (
        filteredBrands.value.indexOf(searchString.value) > -1 &&
        filteredModels.value.indexOf(searchModels.value) > -1
      ) {
        isBrandModel = true
      }
      return isBrandModel
    })

    const settingCarPloiciesValues = () => {
      const carPolicyFuelTypes = JSON.parse(sessionStorage.getItem('car_policy_fuel_type') || '[]')
      const filteredFuelTypes = carPolicyFuelTypes.filter((e) => e !== 'plug_in_hybrid' && e !== 'hybrid')

      if (filteredFuelTypes.length > 0) {
        // Setting fuel values
        sessionStorage.setItem('fuel_type', JSON.stringify(filteredFuelTypes))

        if (sessionStorage.getItem('car_policy_electric_range') && filteredFuelTypes.includes('electric')) {
          const carPolicyElectricRange = JSON.parse(sessionStorage.getItem('car_policy_electric_range') || '[]')
          sessionStorage.setItem('electric_drive_range', JSON.stringify(carPolicyElectricRange))
        }
      }

      // Adding Hybrid true if it exists
      if (carPolicyFuelTypes.includes('hybrid')) {
        sessionStorage.setItem('hybrid', JSON.stringify(true))
      }

      // Adding Plugin Hybrid Range
      if (
        sessionStorage.getItem('car_policy_plug-in-hybrid_range') &&
        !sessionStorage.getItem('plug_in_hybrid_drive_range')
      ) {
        const carPolicyPluginHybridRange = JSON.parse(sessionStorage.getItem('car_policy_plug-in-hybrid_range') || '[]')
        sessionStorage.setItem('plug_in_hybrid', JSON.stringify(true))
        sessionStorage.setItem('plug_in_hybrid_drive_range', JSON.stringify(carPolicyPluginHybridRange))
      }
    }
    const goToResultsAndConfigure = () => {
      // removed only user input
      sessionStorage.removeItem('budget')
      sessionStorage.removeItem('selectedIndex')
      sessionStorage.removeItem('plug_in_hybrid')
      sessionStorage.removeItem('hybrid')
      sessionStorage.removeItem('plug_in_hybrid_drive_range')
      sessionStorage.removeItem('electric_drive_range')
      sessionStorage.removeItem('fuel_type')
      sessionStorage.removeItem('body_type')
      sessionStorage.removeItem('transmission')
      sessionStorage.removeItem('engine_power')
      sessionStorage.removeItem('cargo_space')
      sessionStorage.removeItem('towing_capacity')
      sessionStorage.removeItem('seats')
      sessionStorage.removeItem('doors')
      router.push({ name: 'Step2' })
      settingCarPloiciesValues()
    }

    const showAllCars = () => {
      sessionStorage.removeItem('budget')
      sessionStorage.removeItem('transmission')
      sessionStorage.removeItem('engine_power')
      sessionStorage.removeItem('cargo_space')
      sessionStorage.removeItem('towing_capacity')
      sessionStorage.removeItem('seats')

      if (sessionStorage.getItem('car_policy_fuel_type')) {
        const allowedFuelType = JSON.parse(sessionStorage.getItem('car_policy_fuel_type') || '[]')
        if (allowedFuelType.includes('electric')) {
          sessionStorage.setItem('electric_drive_range', sessionStorage.getItem('car_policy_electric_range') || '[]')
        }
        if (allowedFuelType.includes('plug_in_hybrid')) {
          sessionStorage.setItem('plug_in_hybrid', JSON.stringify(true))
          sessionStorage.setItem(
            'plug_in_hybrid_drive_range',
            sessionStorage.getItem('car_policy_plug-in-hybrid_range') || '[]'
          )
          const removeIndex = allowedFuelType.findIndex((item) => item == 'plug_in_hybrid')
          allowedFuelType.splice(removeIndex, 1)
        }
        if (allowedFuelType.includes('hybrid')) {
          sessionStorage.setItem('hybrid', JSON.stringify(true))
          const removeIndex = allowedFuelType.findIndex((item) => item == 'hybrid')
          allowedFuelType.splice(removeIndex, 1)
        }
        if (allowedFuelType.length > 0) {
          sessionStorage.setItem('fuel_type', JSON.stringify(allowedFuelType))
        }
      } else {
        sessionStorage.removeItem('fuel_type')
        sessionStorage.removeItem('plug_in_hybrid')
        sessionStorage.removeItem('hybrid')
        sessionStorage.removeItem('plug_in_hybrid_drive_range')
        sessionStorage.removeItem('electric_drive_range')
      }

      if (sessionStorage.getItem('car_policy_body_type')) {
        const allowedBodyType = JSON.parse(sessionStorage.getItem('car_policy_body_type') || '[]')
        sessionStorage.setItem('body_type', JSON.stringify(allowedBodyType))
      } else {
        sessionStorage.removeItem('body_type')
      }

      if (sessionStorage.getItem('car_policy_doors')) {
        const allowedDoors = JSON.parse(sessionStorage.getItem('car_policy_doors') || '[]')
        sessionStorage.setItem('doors', JSON.stringify(parseInt(allowedDoors.min)))
      } else {
        sessionStorage.removeItem('doors')
      }

      if (sessionStorage.getItem('car_policy_brands_models')) {
        const brandsModelsArray = JSON.parse(sessionStorage.getItem('car_policy_brands_models') || '')
        sessionStorage.setItem('brands', JSON.stringify(brandsModelsArray))
      } else {
        sessionStorage.removeItem('brands')
      }
      router.push({ name: 'Step2' })
    }
    const removeBrand = () => {
      selectedBrand.value = ''
    }
    const removeModel = () => {
      selectedModel.value = ''
    }
    onUnmounted(() => {
      store.commit(MutationTypes.SET_SEARCH_STRING, '')
      store.commit(MutationTypes.SET_SEARCH_MODELS, '')
    })
    return {
      selectedBrand,
      selectedModel,
      models,
      selectBrand,
      selectModel,
      goToWizard,
      carBrandsModels,
      loader,
      goToResultsAndConfigure,
      isBrandModelExist,
      filteredBrands,
      filteredModels,
      removeBrand,
      removeModel,
      componentKey,
      showAllCars,
      displayUserSettingPopup,
      goToAccount
    }
  }
})
